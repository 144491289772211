const API_BASE = "http://test-myditex.whatson-web.com";
const MEDIA_BASE = "http://test-myditex.whatson-web.com";

const APICONFIG = {
        environment: "staging",
	IAM: "https://staging.iam.myditex.whatson.io",
	CRM: "https://staging.crm.myditex.whatson.io",
        PIM: "https://staging.pim.myditex.whatson.io",
	title: "MyDitex ::: Membre",
	url: API_BASE,
	assetUrl: `/img`,
	icons: `/icomoon/demo.html`,
	ckeditorInstance: `/ckeditor/ckeditor.js`,
	finderParams: {
		url: `/api`,
		endPointMedia: "/media",
		assetUrl: `/img`,
	},
};

module.exports = APICONFIG;
